import React from "react";
import { Box, Container, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import * as styles from "../../styles/ContactPage/HeroContact.module.scss";

const HeroContact = () => {
  return (
    <Box className={styles.heroContactWrapper}>
      <Container>
        {/* Fixation */}
        <Box className={styles.heroContactHead}>
          <Typography variantMapping="p">Have any questions?</Typography>
          <Typography variant="h1">Contact Quickxi</Typography>
          <Typography variantMapping="p">
            We are here to help! Just fill out the form below to get in touch
            with us. Our support team will get back to you soon.
          </Typography>
          <Box className={styles.mailWrapper}>
            <EmailIcon
              sx={{
                fill: "#FFFFFF",
              }}
            />
            <Typography>info@quickxi.com</Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroContact;
