import { useField } from "formik";
import React from "react";
import * as styles from "../../../styles/ContactPage/ContactForm.module.scss";
import { Box } from "@mui/material";
import { Link } from "gatsby";

const CustomCheckbox = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Box className={styles.checkboxWrapper}>
        <Box className={styles.acceptBox}>
          <input {...field} {...props} />
          <p>
            I accept the{" "}
            <Link to="">
              <span style={{ fontWeight: "500", color: "#303030" }}>
                terms and conditions
              </span>
            </Link>
          </p>
        </Box>
        {meta.error && meta.touched ? (
          <div className={styles.errorMessage}>{meta.error}</div>
        ) : null}
      </Box>
    </>
  );
};

export default CustomCheckbox;
