import React from "react";
import Layout from "../Components/Common/Layout";
// import ContactForm from "../Components/ContactPage/ContactForm";
import HeroContact from "../Components/ContactPage/HeroContact";
import Seo from "../Components/seo";
import { Helmet } from "react-helmet";
import ContactFormSecond from "../Components/ContactPage/ContactFormSecond";

const contact = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <meta name="robots" content="max-image-preview:standard" />
      </Helmet>
      <Seo
        title="Get in touch - Contact Us"
        description="Want to know more about Quickxi features and subscription packages? Or want to give a suggestion. We would love to hear from your side!"
        tag="index"
        pageUrl="contact-us/"
      />
      <HeroContact />
      {/* <ContactForm /> */}
      <ContactFormSecond />
    </Layout>
  );
};

export default contact;
