import React, { useRef, useState } from "react";
import { Container, Box, Button, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { contactFormSchema } from "./Schemas";
import "react-phone-number-input/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import * as styles from "../../styles/ContactPage/ContactForm.module.scss";
import FullName from "./FormFields/FullName";
import Email from "./FormFields/Email";
import PhoneInput from "react-phone-number-input";
import ProductSelect from "./FormFields/ProductSelect";
import HearSelect from "./FormFields/HearSelect";
import Message from "./FormFields/Message";
import CustomCheckbox from "./FormFields/Checkbox";
import ContactImage from "../../images/Contact-Image.svg";

const ContactFormSecond = () => {
  const [captcha, isCaptcha] = useState(false);
  const captchaRef = useRef();

  const onChangeReCaptcha = (value) => {
    isCaptcha({ ...captcha, code: value, validation: true });
  };

  return (
    <Container>
      <Box className={styles.contactFormWrapper}>
        <Formik
          initialValues={{
            fullname: "",
            email: "",
            PhoneNumber: "",
            product: "",
            hear: "",
            message: "",
            acceptedTos: false,
          }}
          validationSchema={contactFormSchema}
          onSubmit={(values, action) => {
            axios
              .post(`https://st-backend-quickxi.invo.zone/api/contact-uses`, {
                data: {
                  FullName: values?.fullname,
                  Email: values?.email,
                  PhoneNumber: values?.PhoneNumber,
                  Message: values?.message,
                  product: values?.product,
                  hear: values?.hear,
                  recaptchaToken: captcha?.code,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  action.setStatus({ success: "Email sent !" });
                  setTimeout(() => {
                    captchaRef.current.reset();
                    isCaptcha(false);
                    action.resetForm();
                  }, 3000);
                } else {
                }
              })
              .catch((error) => {
                action.setStatus({ fail: "Email not sent !" });
                action.setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting, values, setFieldValue, handleBlur, status }) => (
            <Form>
              <Box className={styles.flexFormDiv}>
                <FullName
                  label="Your name"
                  name="fullname"
                  type="text"
                  placeholder="Enter your name"
                />
                <Email
                  label="Your email"
                  name="email"
                  type="email"
                  placeholder="Enter your Email"
                />
              </Box>
              <Box className={styles.flexFormDiv}>
                <Box className={styles.phone}>
                  <label htmlFor="PhoneNumber">Phone Number</label>
                  <PhoneInput
                    className="phone"
                    placeholder="Phone Number"
                    name="PhoneNumber"
                    value={values.PhoneNumber}
                    onChange={(e) => setFieldValue("PhoneNumber", e)}
                    onBlur={handleBlur("PhoneNumber")}
                  />
                </Box>
                <ProductSelect label="Products" name="product">
                  <option value="">Select a product</option>
                  <option value="grocery">Grocery</option>
                  <option value="restaurant">Restaurant</option>
                  <option value="online-shop">Online Shop</option>
                  <option value="on-demand">On demand</option>
                  <option value="general-inquiry">General Inquiry</option>
                </ProductSelect>
              </Box>
              <HearSelect label="How did you hear about Quickxi?" name="hear">
                <option value="">Where you hear about us?</option>
                <option value="google-search">Google Search</option>
                <option value="social-media">Social Media</option>
                <option value="other">other</option>
              </HearSelect>
              <Message
                label="Your message"
                name="message"
                placeholder="Enter your Message"
              />
              <CustomCheckbox type="checkbox" name="acceptedTos" />
              <Box>
                <ReCAPTCHA
                  sitekey="6LejghwkAAAAANZFdcYKRZJphQlkw27w6LX45eHa"
                  onChange={onChangeReCaptcha}
                  onExpired={() => isCaptcha(false)}
                  ref={captchaRef}
                />
              </Box>
              <Box className={styles.formButton}>
                <Button disabled={isSubmitting || !captcha.code} type="submit">
                  Submit
                </Button>
                <Box className={styles.successMessage}>
                  <Typography variantMapping="p">
                    {status ? status.success : ""}
                  </Typography>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        <Box className={styles.formImage}>
          <img loading="lazy" src={ContactImage} alt="" />
        </Box>
      </Box>
      <Box className={styles.contactRequestWrapper}>
        <Box className={styles.contactRequestContent}>
          <Typography variant="h2">
            Request a demo with one of our specialists.
          </Typography>
          <Box className={styles.formWrapper}>
            <Button className={styles.contactRequestBtn}>
              Request a free demo
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ContactFormSecond;
