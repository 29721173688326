import React from "react";
import { useField } from "formik";
import { Box } from "@mui/material";
import * as styles from "../../../styles/ContactPage/ContactForm.module.scss";

const FullName = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Box className={styles.name}>
        <label>
          {label}
          <sup style={{ color: "#ba0909" }}>*</sup>
        </label>
        <input {...field} {...props} />
        {meta.error && meta.touched ? (
          <div className={styles.errorMessage}>{meta.error}</div>
        ) : null}
      </Box>
    </>
  );
};

export default FullName;
