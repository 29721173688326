import * as Yup from "yup";

export const contactFormSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  PhoneNumber: Yup.number(),
  product: Yup.string()
    .oneOf([
      "grocery",
      "restaurant",
      "online-shop",
      "on-demand",
      "general-inquiry",
    ])
    .required("Required"),
  hear: Yup.string().oneOf(["google-search", "social-media", "other"]),
  message: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Message is required"),
  acceptedTos: Yup.boolean().oneOf(
    [true],
    "Please accept the terms of services"
  ),
});
